import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import TimesIcon from '../../assets/images/cms/times-icon.svg';

const CLOSE_EVENT = 'close';

@Component({
  name: 'bxl-overlay'
})
export default class Overlay extends Vue {
  timesIcon = TimesIcon;

  handleClose() {
    this.$emit(CLOSE_EVENT);
  }

  handleCloseFromBg({ target }: any) {
    if (target.classList.contains('overlay')) {
      this.$emit(CLOSE_EVENT);
    }
  }
}
