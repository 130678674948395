import Vue from 'vue';
import {Component, Inject, Prop} from 'vue-property-decorator';
import {VideoCardData} from '@/utilities/cmsUtilities';
import SecondarySmallButton from '@/commoncomponents/SecondarySmallButton/SecondarySmallButton.vue';
import PlayIcon from '../../assets/images/cms/circle-play.svg';
import VideoPlayer from '@/commoncomponents/VideoPlayer/VideoPlayer.vue';
import Overlay from '@/commoncomponents/Overlay/Overlay.vue';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';

@Component({
  name: 'cms-preview-video-card',
  components: {
    'overlay': Overlay,
    'video-player': VideoPlayer,
    'small-button': SecondarySmallButton
  }
})
export default class CmsHorizontalCardComponent extends Vue {
  @Prop({
    type: Object
  })
  cardData!: VideoCardData;

  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  // Images from assets
  playIcon = PlayIcon;
  showVideo: boolean = false;

  handlePlay() {
    // handle the video overlay from here or on its parent
    this.$emit('play');
    this.showVideo = !this.showVideo;
  }

  onVideoPlay(): void {
    this.analyticsService.track(analyticsEventNames.LEARNING_VIDEO_RESOURCE_PLAYED);
  }
}
